<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="산업안전보건위원회 회의록 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && !isApprDisabled && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="회의제목"
                  name="proceedingsTitle"
                  v-model="data.proceedingsTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="회의일"
                  name="proceedingsDate"
                  v-model="data.proceedingsDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="4"
                  label="회의내용"
                  name="proceedingsContents"
                  v-model="data.proceedingsContents">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-table
              ref="inTable"
              title="회사참석자 목록"
              :columns="inGrid.columns"
              :gridHeight="gridHeight"
              :data="data.attendeeCoModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              noDataLabel="회사참석자를 추가하세요"
              :hideBottom="true"
              :editable="editable && !disabled"
              :checkClickFlag="false"
              :isExcelDown="false"
              rowKey="proceedingsAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
              </template>
              <template v-slot:customArea="{ props, col }">
                <template v-if="col.name === 'click'">
                  <q-btn 
                    round unelevated 
                    size="10px"
                    color="amber" 
                    icon="search"
                    @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                    >
                    <q-popup-proxy :ref="'proxy' + props.rowIndex" :breakpoint="400">
                      <component
                        :is="col.component"
                        :popupParam="props.row"
                        :rowIndex="props.rowIndex"
                      />
                    </q-popup-proxy>
                  </q-btn>
                </template>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-table
              ref="outTable"
              title="근로자참석자 목록"
              :columns="inGrid.columns"
              :gridHeight="gridHeight"
              :data="data.attendeeEmpModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              noDataLabel="근로자참석자를 추가하세요"
              :hideBottom="true"
              :checkClickFlag="false"
              :isExcelDown="false"
              :editable="editable && !disabled"
              rowKey="proceedingsAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
              </template>
              <template v-slot:customArea="{ props, col }">
                <template v-if="col.name === 'click'">
                  <q-btn 
                    round unelevated 
                    size="10px"
                    color="amber" 
                    icon="search"
                    @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                    >
                    <q-popup-proxy :ref="'proxy' + props.rowIndex" :breakpoint="400">
                      <component
                        :is="col.component"
                        :popupParam="props.row"
                        :rowIndex="props.rowIndex"
                      />
                    </q-popup-proxy>
                  </q-btn>
                </template>
              </template>
            </c-table>
          </div>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="첨부파일" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12col-xl-12" style="height: 280px">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable && !disabled">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
      <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'proceedings-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        proceedingsId: '',
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
    contentHeight: null,
  },
  data() {
    return {
      attachable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OSH_PROCEEDINGS',
        taskKey: '',
      },
      saveUrl: transactionConfig.sai.osh.proceedings.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      serverName: '',
      isSave: false,
      isApproval: false,
      isdelete: false,
      data: {
        proceedingsId: '',
        plantCd: '',
        proceedingsDate: '',
        proceedingsContents: '',
        proceedingsTitle: '',
        regUserId: '',
        chgUserId: '',
        
        attendeeCoModels: [],
        attendeeEmpModels: [],
        deleteAttendeeCoModels: [],
        deleteAttendeeEmpModels: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      inGrid: {
        columns: [
          {
            name: 'attendeeDeptName',
            field: 'attendeeDeptName',
            label: '부서',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            label: '이름',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            label: '직책',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'attendeeSignatureFlag',
            field: 'attendeeSignatureFlag',
            label: '서명여부',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'click',
            field: 'click',
            label: '서명보기',
            style: 'width:70px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'@/pages/sai/osh/proceedingsSignaturePop.vue'}`)
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'center',
            style: 'width: 200px',
          },
        ],
        height: '300px',
        data: [],
      },
    };
  },
  computed: {
    disabled() {
      return this.editable && this.data.approvalStatusCd === 'ASC9999999';
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: !this.disabled && this.popupParam.proceedingsId, // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: transactionConfig.sai.osh.proceedings.complete.url, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000020', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          proceedingsId: this.popupParam.proceedingsId,
          isApprContent: true
        },
        approvalRequestName: '산업안전보건위원회 회의록 ['+this.data.proceedingsTitle+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.proceedingsId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    gridHeight() {
      let height = 250;
      let _margin = 300;
      if (!this.disabled) {
        _margin += 40
      }
      height = this.contentHeight - _margin
      if (height < 250) {
        height = 250
      }
      return String(height) + 'px';
    },
    showApproval() {
      return this.editable
        && !this.disabled
        && this.data.attendeeCoModels.length === this.$_.filter(this.data.attendeeCoModels, {
          attendeeSignatureFlag: 'Y'
        }).length
        && this.data.attendeeEmpModels.length === this.$_.filter(this.data.attendeeEmpModels, {
          attendeeSignatureFlag: 'Y'
        }).length
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.data.proceedingsDate = this.$comm.getToday();
      // url setting
      this.detailUrl = selectConfig.sai.osh.proceedings.get.url;
      this.insertUrl = transactionConfig.sai.osh.proceedings.insert.url;
      this.updateUrl = transactionConfig.sai.osh.proceedings.update.url;
      this.deleteUrl = transactionConfig.sai.osh.proceedings.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.proceedingsId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.proceedingsId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          // regName의 유무로 노출 유무를 조절
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.proceedingsId)
          this.attachable = true;
        },);
      }
    },
    saveData() {
      if (this.popupParam.proceedingsId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',  // 
              message: '저장하시겠습니까?', // 
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',  // 
        message: '삭제하시겠습니까?', // ,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.proceedingsId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.proceedingsId = result.data
      this.$set(this.attachInfo, 'taskKey', this.popupParam.proceedingsId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addCoAttendee() {
      this.popupOptions.title = "회사참석자 검색"; 
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeCoUserPopup;

    },
    closeCoUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.data.attendeeCoModels, {
            attendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.data.attendeeCoModels.splice(0, 0, {
              proceedingsId: this.popupParam.proceedingsId,
              proceedingsAttendeeId: uid(),
              attendeeType: '1',
              attendeeUserId: _item.userId,
              attendeeUserName: _item.userName,
              attendeeDeptName: _item.deptName,
              attendeeJobName: _item.jobName,
              remark: '',
              chgUserId: this.$store.getters.user.userId,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeCoAttendee() {
      let selectData = this.$refs['inTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 
          message: '선택된 항목이 없습니다.', // 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteAttendeeCoModels) {
            this.data.deleteAttendeeCoModels = []
          }
          if (this.$_.findIndex(this.data.deleteAttendeeCoModels, { proceedingsAttendeeId: item.proceedingsAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteAttendeeCoModels.push(item)
          }
          this.data.attendeeCoModels = this.$_.reject(this.data.attendeeCoModels, item)
        })
      }
    },
    addEmpAttendee() {
      this.popupOptions.title = "근로자참석자 검색"; 
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeOutUserPopup;

    },
    closeOutUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.data.attendeeEmpModels, {
            attendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.data.attendeeEmpModels.splice(0, 0, {
              proceedingsId: this.popupParam.proceedingsId,
              proceedingsAttendeeId: uid(),
              attendeeType: '2',
              attendeeUserId: _item.userId,
              attendeeUserName: _item.userName,
              attendeeDeptName: _item.deptName,
              attendeeJobName: _item.jobName,
              remark: '',
              chgUserId: this.$store.getters.user.userId,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeEmpAttendee() {
      let selectData = this.$refs['outTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteAttendeeEmpModels) {
            this.data.deleteAttendeeEmpModels = []
          }
          if (this.$_.findIndex(this.data.deleteAttendeeEmpModels, { proceedingsAttendeeId: item.proceedingsAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteAttendeeEmpModels.push(item)
          }
          this.data.attendeeEmpModels = this.$_.reject(this.data.attendeeEmpModels, item)
        })
      }
    },
    /* eslint-disable no-unused-vars */
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId
          this.data.chgUserId = this.$store.getters.user.userId

          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
